<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <!-- PDF Generator -->

        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          :filename="`proformas_${DateDebut}_${DateFin}`"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="landscape"
          pdf-content-width="100%"

          ref="html2Pdf"
        >
          <section class="p-10" slot="pdf-content">
            <div class="vx-row">
              <div class="vx-col w-full">
                <div class="vx-row">
                  <div class="vx-col w-1/3 mt-base">

                  </div>
                  <div class="vx-col w-1/3 mt-base text-center">
                    <div class="titre_etat_regl">
                      <h1>Etat Proforma</h1>
                    </div>
                  </div>
                  <div class="vx-col w-1/3 mt-base text-right">
                    <!-- {{proforma.createdAt | moment("calendar", "July 10 2011") }} -->
                    <h4>Officine: {{officineName}} </h4>
                  </div>
                </div>
                <div class="vx-row mt-5">
                  <div class="vx-col w-full text-center">
                    <h4>Période du {{DateDebut | moment("calendar", "July 10 2011") }} au {{DateFin | moment("calendar", "July 10 2011") }}</h4>
                  </div>
                </div>
                <div class="vx-row mt-5">
                  <!-- {{reglements_client}} -->
                  <table class="w-full customtable" cellpadding="0" cellspacing="0">
                    <thead>
                      <tr>
                        <th class="customth" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;" ></th>
                        <th class="customth" colspan="1" style="border-right-width: 0px;" >N°Pro</th>
                        <th class="customth" colspan="1" style="border-right-width: 0px;">Date</th>
                        <th class="customth" colspan="2" style="border-right-width: 0px;">Client</th>
                        <th class="customth" colspan="1" style="border-right-width: 0px;">Prescripteur</th>
                        <th class="customth" colspan="1" style="border-right-width: 0px;">Proposition 1</th>
                        <th class="customth" colspan="1" style="border-right-width: 0px;">Proposition 2</th>
                        <th class="customth" colspan="1" style="border-right-width: 0px;">Proposition 3</th>
                        <th class="customth" colspan="1" style="">Conv.</th>
                      </tr>
                    </thead>
                    <!-- v-if="br.length >0" -->
                    <tbody>
                      <template>
                        <tr :key="indextr" v-for="(tr, indextr) in proformas">
                          <td class="customtd" colspan="1" style="border-width: 0px;border-bottom-width: 0px;"></td>
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.numProforma}}</td>
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.DateCommd | moment("calendar", "July 10 2011") }}</td>
                          <td class="customtd" colspan="2" style="border-right-width: 0px;border-bottom-width: 0px;">{{getClientName(tr.IdClient)}}</td>
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{getPrecripteurName(tr.Idprescripteur)}}</td>
                          <td class="customtd text-right" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{propositionUn(tr)}}</td>
                          <td class="customtd text-right" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{propositionDeux(tr)}}</td>
                          <td class="customtd text-right" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{propositionTrois(tr)}}</td>
                          <td class="customtd text-right" colspan="1" style="border-bottom-width: 0px;">{{tr.status ? 'Oui' : 'Non'}}</td>
                        </tr>
                      </template>
                    </tbody>

                    <tfoot>
                      <tr>
                        <th class="customth text-center"  colspan="1" style="border-right-width: 0px;">TOTAL</th>
                        <th class="customth text-center"  colspan="1" style="border-right-width: 0px;"> {{proformas.length}} </th>
                        <th class="customth text-center"  colspan="1" style="border-right-width: 0px;"></th>
                        <th class="customth text-center"  colspan="2" style="border-right-width: 0px;"></th>
                        <th class="customth text-center"  colspan="1" style="border-right-width: 0px;"></th>
                        <th class="customth text-right"  colspan="1" style="border-right-width: 0px;">{{totalPropositionUn(proformas)}}</th>
                        <th class="customth text-right" colspan="1" style="border-right-width: 0px;">{{totalPropositionDeux(proformas)}}</th>
                        <th class="customth text-right" colspan="1" style="border-right-width: 0px;">{{totalPropositionTrois(proformas)}}</th>
                        <th class="customth text-right" colspan="1">{{ tauxConversionProforma ? tauxConversionProforma : 0 }}%</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </vue-html2pdf>

        <!-- PDF Generator end -->
      <vx-card>
        <div class="vx-row">
          <div class="vx-col md:w-1/6 mt-4">
            <label class="text-sm">Date Debut</label>
            <flat-pickr v-model.lazy="DateDebut" :config="{ dateFormat: 'd F Y'}" class="w-full" name="DateDebut" />
          </div>
          <div class="vx-col md:w-1/6 mt-4">
            <label class="text-sm">Date Fin</label>
            <flat-pickr v-model.lazy="DateFin" :config="{ dateFormat: 'd F Y'}" class="w-full" name="DateFin" />
          </div>
          <div class="vx-col md:w-1/6 mt-4">
            <vs-select v-if="principale" label="Officine" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="idOfficine" @change="onSelectOfficine(idOfficine)">
              <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
            </vs-select>
          </div>
          <div class="vx-col md:w-1/6 mt-10">
            <vs-button ref="loadableButton" id="button-with-loading" class="w-full vs-con-loading__container" color="primary" icon-pack="feather" icon="icon-search" @click="getProformaOnSpecifiquePeriode()">VALIDER</vs-button>
          </div>
          <div class="vx-col md:w-1/6 mt-10">
            <vs-button id="pdf-button-with-loading" class="w-full vs-con-loading__container" color="primary" icon-pack="feather" icon="icon-file" @click="printProformaToPdf()">IMPRESSION</vs-button>
          </div>
        </div>
      </vx-card>
    </div>
    <div  class="vx-col w-full mt-5">
      <vx-card id="corps-proforma">
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="vx-row">
              <div class="vx-col w-1/3 mt-base">

              </div>
              <div class="vx-col w-1/3 mt-base text-center">
                <div class="titre_etat_regl">
                  <h1>Etat Proforma</h1>
                </div>
              </div>
              <div class="vx-col w-1/3 mt-base text-right">
                <!-- {{proforma.createdAt | moment("calendar", "July 10 2011") }} -->
                <h4>Officine: {{officineName}} </h4>
              </div>
            </div>
            <div class="vx-row mt-5">
              <div class="vx-col w-full text-center">
                <h4>Période du {{DateDebut | moment("calendar", "July 10 2011") }} au {{DateFin | moment("calendar", "July 10 2011") }}</h4>
              </div>
            </div>
            <div class="vx-row mt-5">
              <!-- {{reglements_client}} -->
              <!-- {{proformas}} -->
              <table class="w-full customtable" cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th class="customth" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;" ></th>
                    <th class="customth" colspan="1" style="border-right-width: 0px;" >N°Pro</th>
                    <th class="customth" colspan="1" style="border-right-width: 0px;">Date</th>
                    <th class="customth" colspan="2" style="border-right-width: 0px;">Client</th>
                    <th class="customth" colspan="1" style="border-right-width: 0px;">Prescripteur</th>
                    <th class="customth" colspan="1" style="border-right-width: 0px;">Proposition 1</th>
                    <th class="customth" colspan="1" style="border-right-width: 0px;">Proposition 2</th>
                    <th class="customth" colspan="1" style="border-right-width: 0px;">Proposition 3</th>
                    <th class="customth" colspan="1" style="">Conv.</th>
                  </tr>
                </thead>
                <!-- v-if="br.length >0" -->
                <tbody>

                  <template>
                    <tr :key="indextr" v-for="(tr, indextr) in proformas">
                      <td class="customtd" colspan="1" style="border-width: 0px;border-bottom-width: 0px;"></td>
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.numProforma}}</td>
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.DateCommd | moment("calendar", "July 10 2011") }}</td>
                      <td class="customtd" colspan="2" style="border-right-width: 0px;border-bottom-width: 0px;">{{getClientName(tr.IdClient)}}</td>
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{getPrecripteurName(tr.Idprescripteur)}}</td>
                      <td class="customtd text-right" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{propositionUn(tr)}}</td>
                      <td class="customtd text-right" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{propositionDeux(tr)}}</td>
                      <td class="customtd text-right" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{propositionTrois(tr)}}</td>
                      <td class="customtd text-right" colspan="1" style="border-bottom-width: 0px;">{{tr.status ? 'Oui' : 'Non'}}</td>
                    </tr>
                  </template>
                </tbody>

                <tfoot>
                  <tr>
                    <th class="customth text-center"  colspan="1" style="border-right-width: 0px;">TOTAL</th>
                    <th class="customth text-center"  colspan="1" style="border-right-width: 0px;"> {{proformas.length}} </th>
                    <th class="customth text-center"  colspan="1" style="border-right-width: 0px;"></th>
                    <th class="customth text-center"  colspan="2" style="border-right-width: 0px;"></th>
                    <th class="customth text-center"  colspan="1" style="border-right-width: 0px;"></th>
                    <th class="customth text-right"  colspan="1" style="border-right-width: 0px;">{{totalPropositionUn(proformas)}}</th>
                    <th class="customth text-right" colspan="1" style="border-right-width: 0px;">{{totalPropositionDeux(proformas)}}</th>
                    <th class="customth text-right" colspan="1" style="border-right-width: 0px;">{{totalPropositionTrois(proformas)}}</th>
                    <th class="customth text-right" colspan="1">{{ tauxConversionProforma }}%</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
/* lazy loading Vue components */
const flatPickr = () => import('vue-flatpickr-component')
import 'flatpickr/dist/flatpickr.css'
const VueHtml2pdf = () => import('vue-html2pdf')
export default {
  components: {
    flatPickr,
    VueHtml2pdf
  },
  data () {
    return {
      activeUserInfos: null,
      DateDebut: null,
      DateFin: null,
      idOfficine: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null,
      officineName: ''
    }
  },
  computed: {
    officines () {
      return this.$store.state.officine.officines
    },
    proformas () {
      return this.$store.state.etat.proformas
    },
    principale () {
      return this.$store.getters['officine/officineIsPrincipale'](JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null)
    },
    tauxConversionProforma () {
      const proformas = this.$store.state.etat.proformas
      const total = proformas.length
      const totalConv = proformas.filter(p => p.status === true).length

      if (total >= 1) {
        const taux = ((totalConv || 0) * 100) / total
        return taux.toFixed(2) // Formatage pour obtenir deux chiffres après la virgule
      } else {
        return '0.00' // Retourne '0.00' si le total est inférieur à 1
      }
    }
  },
  methods: {
    moneyFormatter (montant) {
      /* Formatage du calcul */
      const val = (montant / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    getPrecripteurName (id) {
      return this.$store.getters['prescripteur/getPrecripteurName'](id)
    },
    getClientName (id) {
      return this.$store.getters['client/getClientName'](id)
    },
    onSelectOfficine (id) {
      const officine = this.$store.state.officine.officines.find((o) => o._id === id)
      this.officineName = officine.NomOffic
    },
    propositionUn (data) {
      const PrixBrute = +(data.PrixMont1 ? data.PrixMont1 : 0) + (+(data.PrixVerG1 ? data.PrixVerG1 : 0) + +(data.PrixVerD1 ? data.PrixVerD1 : 0))
      const montantRemise = ((data.RemiseCom ? data.RemiseCom : 0) * PrixBrute) / 100
      const total = PrixBrute - montantRemise
      /* Formatage du calcul */
      const val = (total / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

    },
    propositionDeux (data) {
      const PrixBrute = +(data.PrixMont2 ? data.PrixMont2 : 0) + (+(data.PrixVerG2  ? data.PrixVerG2  : 0) + +(data.PrixVerD2 ? data.PrixVerD2 : 0))
      const montantRemise = ((data.RemiseCom ? data.RemiseCom : 0) * PrixBrute) / 100
      const total = PrixBrute - montantRemise
      /* Formatage du calcul */
      const val = (total / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    propositionTrois (data) {
      const PrixBrute = +(data.PrixMont3 ? data.PrixMont3 : 0) + (+(data.PrixVerG3 ? data.PrixVerG3 : 0) + +(data.PrixVerD3 ? data.PrixVerD3 : 0))
      const montantRemise = ((data.RemiseCom ? data.RemiseCom : 0) * PrixBrute) / 100
      const total = PrixBrute - montantRemise
      /* Formatage du calcul */
      const val = (total / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    totalPropositionUn (proformas) {
      const montant = proformas.reduce((accumulator, proforma) => {
        const PrixBrute = (proforma.PrixMont1 ? proforma.PrixMont1 : 0) + (+(proforma.PrixVerG1 ? proforma.PrixVerG1 : 0) + +(proforma.PrixVerD1 ? proforma.PrixVerD1 : 0))
        const montantRemise = ((proforma.RemiseCom ? proforma.RemiseCom : 0) * PrixBrute) / 100
        const total = PrixBrute - montantRemise
        return accumulator + +total
      }, 0)
      return this.moneyFormatter(montant)
    },
    totalPropositionDeux (proformas) {
      const montant = proformas.reduce((accumulator, proforma) => {
        const PrixBrute = (proforma.PrixMont2 ? proforma.PrixMont2 : 0) + (+(proforma.PrixVerG2 ? proforma.PrixVerG2 : 0) + +(proforma.PrixVerD2 ? proforma.PrixVerD2 : 0))
        const montantRemise = ((proforma.RemiseCom ? proforma.RemiseCom : 0) * PrixBrute) / 100
        const total = PrixBrute - montantRemise
        return accumulator + +total
      }, 0)
      return this.moneyFormatter(montant)
    },
    totalPropositionTrois (proformas) {
      const montant = proformas.reduce((accumulator, proforma) => {
        const PrixBrute = (proforma.PrixMont3 ? proforma.PrixMont3 : 0) + (+(proforma.PrixVerG3 ? proforma.PrixVerG3 : 0) + +(proforma.PrixVerD3 ? proforma.PrixVerD3 : 0))
        const montantRemise = ((proforma.RemiseCom ? proforma.RemiseCom : 0) * PrixBrute) / 100
        const total = PrixBrute - montantRemise
        return accumulator + +total
      }, 0)
      return this.moneyFormatter(montant)
    },
    printProformaToPdf () {

      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#pdf-button-with-loading',
        type: 'sound',
        scale: 0.45
      })

      setTimeout(() => {
        this.$vs.loading.close('#pdf-button-with-loading > .con-vs-loading')
      }, 10000)

      this.$refs.html2Pdf.generatePdf()

    },
    getProformaOnSpecifiquePeriode () {
      const payload = {
        DateDebut: this.DateDebut,
        DateFin: this.DateFin,
        idOfficine: this.idOfficine
      }

      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#button-with-loading',
        type: 'sound',
        scale: 0.45
      })
      /* get officine clients */
      this.getClientsOfficine(this.idOfficine)

      this.$store.dispatch('etat/getProformaOnSpecifiquePeriode', payload)
        .then(() => {
          this.$vs.loading.close('#button-with-loading > .con-vs-loading')
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close('#button-with-loading > .con-vs-loading')
        })

    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {
          this.onSelectOfficine(this.idOfficine)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getPrescripteurs () {
      this.$store.dispatch('prescripteur/getPrescripteurs')
        .then(() => {
        })
        .catch(err => { console.log(err) })
    },
    getClientsOfficine (id) {
      this.$store.dispatch('client/getClientsOfficine', id)
        .then(() => {
        })
        .catch(err => { console.log(err) })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  created () {
    if (!(this.$store.state.officine.officines.length > 0)) this.getOfficines()
    if (!(this.$store.state.prescripteur.prescripteurs.length > 0)) this.getPrescripteurs()
  }
}
</script>
<style scoped>
.not-data-table{
  display: none;
}

/* table {
  border:   1px solid black;
} */
/* th, td {
  border: 1px solid black;
  padding: 4px;
} */

.customtable, .customth, .customtd {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 4px;
  font-size: 12px;
}

.titre_etat_regl{
  border: 1px solid;
  background-color: #CFCFCF;
}
</style>
